.podnikatel-header {
  padding-top: 0;

  .podnikatel-header__flow-pageName {
    display: none;
  }

  //trikolora nad headerom
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background-image: linear-gradient(
      90deg,
      #fff 0,
      #fff 33.3%,
      #003183 0,
      #003183 66.6%,
      #d0190f 0,
      #d0190f
    );
    background-size: 150px 100%;
    background-repeat: repeat;
  }

  //modry pas za headerom
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: govuk-colour('dark-blue');
  }

  .podnikatel-header__preHeader {
    background: govuk-colour('black'); //#131313;
    margin-bottom: govuk-spacing(6);
    padding: govuk-spacing(3) 0;

    .podnikatel-header__preHeader-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;

      //nazov sluzby
      .podnikatel-header__serviceName {
        color: govuk-colour('white');
        font-size: 1.1875rem;
      }

      //language selector
      .idsk-header-extended__language {
        padding: 0;

        .idsk-header-extended__language-button {
          color: govuk-colour('white');
          padding-right: govuk-spacing(4);

          &::after {
            border-color: govuk-colour('white') transparent transparent;
          }
        }

        .idsk-header-extended__language-list {
          border: 1px solid govuk-colour('black');
          width: 8.125rem;
        }
      }
    }
  }

  .idsk-header-extended__container {
    .podnikatel-header__logo-user-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .podnikatel-header-pageName {
        display: none;
        color: govuk-colour('dark-blue');
        margin-bottom: 0;
      }

      .idsk-header__user {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;

        .idsk--header__user-name {
          text-align: left;
        }

        svg {
          fill: govuk-colour('black');
          width: 2.083125rem;
          height: 2.083125rem;
        }
      }
    }

    .idsk-header-extended__content {
      margin-bottom: govuk-spacing(3) !important;

      .idsk-header-extended__navigation {
        .idsk-header-extended__navigation-item {
          .idsk-header-extended__navigation-submenu {
            padding-top: govuk-spacing(3);

            ul {
              li {
                width: auto;
                padding: govuk-spacing(3) govuk-spacing(3) govuk-spacing(3)
                  govuk-spacing(2);
              }
            }
          }
        }
      }
    }

    .idsk-header-extended__logo {
      margin-right: initial;

      .idsk-header-extended__logotype-crown {
        svg {
          width: 100%;
          height: auto;
          max-width: 11.25rem;
        }
      }
    }

    .idsk-header-extended__mobile {
      background: govuk-colour('white');
      position: relative;
      padding: 0;
      width: 100% !important;
      height: calc(100vh - 7.8125rem) !important;
      overflow-y: scroll;

      .idsk-header-extended__mobile-close {
        color: govuk-colour('black');
      }

      .idsk-header-extended__navigation {
        .idsk-header-extended__navigation-item {
          background: govuk-colour('white');

          .idsk-header-extended__link {
            color: govuk-colour('black');

            &::after {
              border-color: govuk-colour('black') transparent transparent;
            }
          }

          .idsk-header-extended__navigation-submenu {
            background: govuk-colour('white');

            ul {
              li {
                &::before {
                  color: govuk-colour('black');
                }
                a {
                  color: govuk-colour('black');
                }
              }
            }
          }
        }
      }

      .idsk-header-extended__hr {
        border-top: 1px solid #e7e8ea;
      }

      .idsk-header__user {
        margin-top: govuk-spacing(7);
        margin-bottom: govuk-spacing(4);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 1;

        .idsk--header__user-name {
          text-align: left;
        }

        svg {
          fill: govuk-colour('black');
          width: 2.8125rem;
          height: 2.8125rem;
          min-width: 2.1875rem;
        }
      }

      .idsk-header-extended__language {
        margin-bottom: govuk-spacing(3);
        margin-top: 0 !important;
        width: 100% !important;

        .idsk-header-extended__language-button {
          color: govuk-colour('black');

          &::after {
            border-color: govuk-colour('black') transparent transparent;
          }
        }

        .idsk-header-extended__language-list {
          .idsk-header-extended__language-list-item {
            .idsk-header-extended__language-list-link {
              color: govuk-colour('black');
            }
          }
        }
      }
    }
  }
}

//Mobile menu
@media screen and (max-width: 48.0525em) {
  .podnikatel-header__flow {
    .idsk-header-extended__container {
      display: none;
    }

    .podnikatel-header__serviceName {
      display: none !important;
    }

    .podnikatel-header__flow-pageName {
      display: block !important;
      text-decoration: none;
      margin-bottom: 0 !important;
      font-family: 'Source Sans Pro', 'Arial', sans-serif !important;
      color: govuk-colour('white');
    }

    &::after {
      display: none;
    }
  }

  .idsk-header-extended-notification {
    padding-top: 0 !important;
  }

  .idsk-header-extended__navigation-item .idsk-header-extended__arrow {
    display: block;
    padding-left: 0;

    &::after {
      position: absolute;
      left: unset;
      right: 0;
    }
  }

  .idsk-header-extended.podnikatel-header.idsk-header-extended--show-mobile-menu {
    &::after {
      content: none;
    }
  }

  .podnikatel-header {
    padding-top: 0 !important;
    position: relative !important;

    .idsk-header-extended__container {
      .idsk-header-extended__mobile {
        .idsk-header-extended__language {
          span {
            font-size: 1.875rem;
          }
          .idsk-header-extended__language-list {
            .idsk-header-extended__language-list-item {
              .idsk-header-extended__language-list-link {
                font-size: 1.875rem;
              }
            }
          }
        }

        .idsk-header-extended__content {
          .idsk-header-extended__navigation {
            .idsk-header-extended__navigation-item {
              .idsk-header-extended__navigation-submenu {
                li {
                  display: flex;
                  align-items: center;

                  &::before {
                    color: govuk-colour('dark-blue');
                    font-size: 1.875rem;
                    margin-top: -0.375rem;
                  }
                }

                a {
                  font-size: 1.3125rem;
                }
              }

              .idsk-header-extended__link {
                font-size: 1.875rem;
              }
            }
          }
        }
      }
    }

    .podnikatel-header__preHeader {
      margin-bottom: 0;

      .podnikatel-header__preHeader-flex {
        .podnikatel-header__serviceName {
          padding-left: 0.125rem;
        }
      }
    }

    .idsk-header-extended__container {
      .podnikatel-header__logo-user-flex {
        padding: 1rem 0;
        max-width: 100%;

        a,
        .idsk-header__user {
          display: none !important;
        }

        .idsk-header-extended__menu-button {
          position: relative;
          top: 0;
          right: 0;
          width: 2.5rem;
          height: 2.5rem;
          flex-shrink: 0;
        }

        .idsk-header-extended__mobile-close {
          position: relative;
          flex-shrink: 0;
          top: 0;
          right: 0;
          width: 2.5rem;
          height: 2.5rem;
          padding: 0;

          &::after {
            content: unset;
          }
        }

        .podnikatel-header-pageName {
          display: block !important;
        }
      }

      .idsk-header-extended__mobile {
        .idsk-header-extended__logo {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 40rem) {
  .podnikatel-header {
    .idsk-header-extended__container {
      .idsk-header-extended__mobile {
        height: calc(100vh - 6.5rem) !important;
      }

      .idsk-header-extended__language {
        span {
          font-size: 1.5rem !important;
        }
        .idsk-header-extended__language-list {
          .idsk-header-extended__language-list-item {
            .idsk-header-extended__language-list-link {
              font-size: 1.125rem !important;
            }
          }
        }
      }

      .idsk-header-extended__content {
        .idsk-header-extended__navigation {
          .idsk-header-extended__navigation-item {
            .idsk-header-extended__navigation-submenu {
              a {
                font-size: 1.125rem !important;
              }
            }

            .idsk-header-extended__link {
              font-size: 1.5rem !important;
            }
          }
        }
      }
    }

    .podnikatel-header__preHeader {
      .podnikatel-header__preHeader-flex {
        .podnikatel-header__serviceName {
          font-size: 1rem;
          padding-left: 0.0625rem;
        }
      }
    }

    .idsk-header-extended__container {
      .podnikatel-header__logo-user-flex {
        padding: 0.5rem 0;

        .podnikatel-header-pageName {
          font-size: 1.1875rem !important;
        }
      }
    }
  }
}
