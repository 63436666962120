.collapsibleFilter {
  .govuk-details__summary {
    padding-left: 0;
    padding-right: 25px;

    &::before {
      background-position: right;
    }
  }

  .govuk-details[open] > .govuk-details__summary:before {
    background-position: right;
  }

  .collapsibleFilter__title {
    color: govuk-colour('blue');
    text-decoration: underline;
  }

  .collapsibleFilter__content {
    display: none;
  }

  .podnikatel-filter-button-container {
    text-align: right;
  }

  .open {
    display: block !important;
  }

  .mobile-heading,
  .mobile-footer,
  .mobile-section-break {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .collapsibleFilter {
    position: relative;

    .podnikatel-filter-button-container {
      text-align: left;
    }

    .govuk-details {
      font-size: 1.1875rem;
    }

    .collapsibleFilter__content {
      position: fixed;
      top: 0;
      left: 0;
      background-color: govuk-colour('white');
      height: 100vh;
      width: 100%;
      padding: 1rem;
      box-sizing: border-box;
      z-index: 11;
      overflow-y: auto;

      .mobile-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .mobile-section-break,
      .mobile-footer {
        display: block;
      }
    }
  }
}
