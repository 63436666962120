$govuk-fonts-path: '../../../node_modules/@id-sk/frontend/govuk/assets/fonts/';
$govuk-images-path: '../../../node_modules/@id-sk/frontend/govuk/assets/images/';

@import 'normalize.css';
@import '../../../node_modules/@id-sk/frontend/idsk/all.scss';
@import '../../includes/components/header-extended/podnikatel-header.scss';
@import '../../includes/components/pagination/paginations.scss';
@import './modal.scss';
@import './collapsibleFilter.scss';
@import 'accessible-autocomplete';
@import './autocomplete.scss';

//Fix vertically aligned radios spacing
.govuk-radios__item {
  margin-bottom: govuk-spacing(2);
}

//Fix inconsistency with example on IDSK page (without this focus box shadow is too big and it shouldn't even be here )
.govuk-input--error {
  &:focus {
    box-shadow: none;
  }
}

//Colors, borders, bgcolors
.podnikatel-color-disabled {
  color: govuk-colour('dark-grey');
}

.podnikatel-display-none {
  display: none;
}

.podnikatel-border-disabled {
  border-color: govuk-colour('dark-grey');
}

.podnikatel-table-border-top {
  border-top: 1px solid #bfc1c3;
}

.podnikatel-no-border {
  border: none;
}

.podnikatel-border-black {
  border: 2px solid govuk-colour('black');
}

.podnikatel-background-color-grey {
  background-color: govuk-colour('lighter-grey');
}

.podnikatel-background-color-white {
  background-color: govuk-colour('white') !important;
}

//Text aligning
.podnikatel-text-align-right {
  text-align: right;
}

.podnikatel-text-align-center {
  text-align: center;
}

.podnikatel-vertical-align-middle {
  vertical-align: middle;
}

//Inline buttons
.govuk-button-group {
  margin-right: -15px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;

  .govuk-button {
    margin-right: 15px;
  }
}

//Fix inline buttons break whole page
@media screen and (max-width: 640px) {
  .govuk-button-group {
    margin-right: 0 !important;
  }
}

//Hide open/close all accordion button
.govuk-accordion__controls {
  display: none;
}

.govuk-accordion__open-all {
  visibility: hidden !important;
}

//Table overfloww scroll
.podnikatel-table-overflow-auto {
  overflow-x: auto !important;
}

//Hide mobile versions of tables
.podnikatel-table-mobile {
  display: none;
}

//Custom container - original IDSK has width of 960px and that is not enough
.govuk-width-container {
  max-width: 1200px;
  margin-right: 15px;
  margin-left: 15px;
}

@media screen and (min-width: 1260px) {
  .govuk-width-container {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media screen and (max-width: 768px) {
  main.govuk-main-wrapper {
    padding-top: govuk-spacing(2);
  }
}

.block-link {
  display: flex;
  align-items: center;

  svg {
    margin-right: govuk-spacing(1);
  }
}

.td-no-border {
  border-bottom: none;
}

//Border which indicates separating from data in mobile table view
.podnikatel-table-border-bottom-3 {
  border-bottom: 3px solid #bfc1c3;
}

.td-no-wrap {
  white-space: nowrap;
}

.inline-link-wrapper {
  .govuk-details__text {
    display: flex;
    flex-direction: row;

    .inline-link {
      display: flex;
      align-items: center;
      margin-right: govuk-spacing(3);

      &:last-of-type {
        margin-right: none;
      }

      svg {
        margin-right: govuk-spacing(1);
      }
    }
  }
}

//Reporty - zobrazenie akcii - always open
.podnikatel-actions-desktop,
.podnikatel-actions-mobile {
  display: flex;
  flex-direction: row;

  .inline-link {
    display: flex;
    align-items: center;
    margin-right: govuk-spacing(3);

    &:last-of-type {
      margin-right: none;
    }

    svg {
      margin-right: govuk-spacing(1);
    }
  }
}

.podnikatel-actions-mobile {
  flex-wrap: wrap;
}

//inpage navigation
.idsk-in-page-navigation__title {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .idsk-in-page-navigation {
    width: 100%;
    margin-left: 0;
    margin-top: -10px;
    position: relative !important;

    .idsk-in-page-navigation__link-number {
      display: none !important;
    }

    .idsk-in-page-navigation-wrapper {
      .idsk-in-page-navigation__link-panel {
        .idsk-in-page-navigation__link-panel-button {
          padding-left: 15px;

          &::before {
            right: 24px;
          }
        }
      }

      .idsk-in-page-navigation__list {
        .idsk-in-page-navigation__list-item--active {
          &::before {
            top: 8px;
          }
        }
      }
    }
  }

  .idsk-in-page-navigation--sticky {
    top: 0;
  }
}

.podnikatel-table-mobile-heading {
  display: none;
}

//hide page title on mobile devices, since page title will be displayed in header
@media screen and (max-width: 768px) {
  .podnikatel-page-heading {
    display: none;
  }

  .podnikatel-table-mobile-heading {
    display: block;
  }
}

@media screen and (max-width: 640px) {
  //Hide desktop version of table
  .podnikatel-table-desktop {
    display: none;
  }

  .podnikatel-mobile-heading {
    font-size: 1.5rem !important;
  }

  .podnikatel-table-mobile {
    display: block;
  }

  .podnikatel-flex-items {
    justify-content: space-between;

    h3 {
      margin-right: 0 !important;
    }
  }
}

//Profil obcana a profil zamestnavatela zakladne udaje flex
.podnikatel-flex-items {
  display: flex;
  align-items: center;

  a {
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }
  }
}

//Min-width for some TD's because of not looking good text-wrapping
.podnikatel-min-width-175 {
  min-width: 10.9375rem;
}

//Prevent scrolling body when mobile menu is open
.overflow-hidden {
  overflow: hidden;
}

//Change govukdetails component icon to custom chevron
.govuk-details__summary {
  padding-right: 25px;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: auto;
    position: absolute;
    background: url('../icons/chevron_bottom.svg') no-repeat left;
    border: none;
    border-width: unset;
    clip-path: unset;
    border: none !important;
  }
}

.govuk-details[open] > .govuk-details__summary:before {
  content: '';
  width: 100%;
  height: auto;
  position: absolute;
  background: url('../icons/chevron_top.svg') no-repeat left;
  border: none;
  border-width: unset;
  clip-path: unset;
}
