.idsk-pagination-container {
  @include govuk-responsive-margin(6, 'bottom');
}

.idsk-pagination-prev a,
.idsk-pagination-next a {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.idsk-pagination-next,
.idsk-pagination-prev {
  display: flex;
  align-items: center;

  a {
    font-size: 1.1875rem;
    white-space: nowrap;
  }

  svg {
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
  }
}

.idsk-pagination-next {
  margin-left: govuk-spacing(3);
}

.idsk-pagination-prev {
  margin-right: govuk-spacing(3);
}

.podnikatel-pagination {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}

.idsk-pagination-list {
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1875rem;
    margin: 0;

    .idsk-pagination-placeholder {
      color: govuk-colour('black');
      font-weight: bold;
    }

    @include govuk-responsive-margin(3, 'left');
    @include govuk-responsive-margin(3, 'right');

    > li {
      padding-left: 0;

      padding: 0.25rem 0.75rem;
      line-height: 1.25rem;

      > a {
        text-decoration: none;

        &:hover,
        &:focus {
          text-decoration: underline;
          background: transparent;
        }

        &:focus {
          background: $govuk-focus-colour;
          outline: 3px solid $govuk-focus-colour;
          outline-offset: 0;
        }
      }
    }

    li.active {
      padding: 0.25rem 0.75rem;
      color: govuk-colour('black');
      font-weight: bold;
    }

    > .active {
      span,
      a {
        color: govuk-colour('black');
        font-weight: bold;
        cursor: default;
        background: transparent;
      }

      a {
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (max-width: 730px) {
  .podnikatel-pagination {
    display: block;

    .idsk-pagination-prev {
      margin-bottom: govuk-spacing(1);
    }

    .idsk-pagination-next {
      justify-content: flex-end;
    }
  }
}

@media screen and (max-width: 640px) {
  .podnikatel-pagination {
    .idsk-pagination-prev,
    .idsk-pagination-next {
      svg {
        width: 1.85rem;
        height: 1.85rem;
      }

      a {
        font-size: 1rem;
      }
    }

    .idsk-pagination-list {
      li {
        font-size: 1rem;
      }
    }
  }
}
